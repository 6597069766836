import clsx from 'clsx'
import { FunctionComponent } from 'react'

import Logo, { Logos } from '../../../atoms/logo'
import Paragraph from '../../../atoms/paragraph'
import Title, { TitleSize } from '../../../atoms/title'
import Button, { ButtonSize } from '../../../molecules/button'
import Image from '../../../molecules/image'
import BannerAppCTA from '../components/app-cta'
import { BannerLogo, BannerProps, BannerTheme, BannerVariant } from '../index'

import styles from './banner.module.scss'

const BannerDefault: FunctionComponent<BannerProps> = (
  {
    image,
    logo,
    title,
    subTitle,
    text,
    buttonLabel,
    theme = BannerTheme.dark,
    variant = BannerVariant.default,
    link,
    className,
    ref,
    click,
    useOutset= false
  }
) => {
  const Tag = link
    ? 'a'
    : 'div'

  const prefix = `${theme}`

  return <Tag
    className={clsx(
      styles.container,
      theme === BannerTheme.dark ? styles.wrapper : undefined,
      theme === BannerTheme.light ? styles.wrapperLight : undefined,
      theme === BannerTheme.vrtmax ? styles.wrapperVrtmax : undefined,
      theme === BannerTheme.OlympischeSpelen2024 ? styles.wrapperOlympischeSpelen2024 : undefined,
      theme === BannerTheme.ParalympischeSpelen2024 ? styles.wrapperOlympischeSpelen2024 : undefined,
      theme === BannerTheme.EKWielrennen2024 ? styles.wrapperEKWielrennen2024 : undefined,
      variant === BannerVariant.appPromo && styles.variantAppPromo,
      useOutset && styles.outset,
      className
    )}
    onClick={() => click()}
    href={link ? link : undefined}
    target={link ? '_blank' : undefined}
    ref={ref}
  >
    <div className={styles.background}>
      <div className={styles.backgroundLeft}>
        <svg width="242" height="237" viewBox="0 0 242 237" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="109.377" y="167.484" width="60" height="351" transform="rotate(-170.234 109.377 167.484)" fill={`url(#${prefix}paint0_linear_8438_107897)`} fillOpacity="0.7"/>
          <rect x="182.377" y="97.4836" width="80" height="351" transform="rotate(-170.234 182.377 97.4836)" fill={`url(#${prefix}paint1_linear_8438_107897)`} fillOpacity="0.6"/>
          <rect x="19.7102" y="127.484" width="20" height="351" transform="rotate(-170.234 19.7102 127.484)" fill={`url(#${prefix}paint2_linear_8438_107897)`} fillOpacity="0.6"/>
          <defs>
            <linearGradient id={`${prefix}paint0_linear_8438_107897`} x1="139.377" y1="167.484" x2="139.377" y2="518.484" gradientUnits="userSpaceOnUse">
              <stop stopColor="#83FF00" stopOpacity="0"/>
              <stop offset="1" stopColor="#83FF00" stopOpacity="0.5"/>
            </linearGradient>
            <linearGradient id={`${prefix}paint1_linear_8438_107897`} x1="222.377" y1="97.4836" x2="222.377" y2="448.484" gradientUnits="userSpaceOnUse">
              <stop stopColor="#83FF00" stopOpacity="0"/>
              <stop offset="1" stopColor="#83FF00" stopOpacity="0.5"/>
            </linearGradient>
            <linearGradient id={`${prefix}paint2_linear_8438_107897`} x1="29.7102" y1="127.484" x2="29.7102" y2="478.484" gradientUnits="userSpaceOnUse">
              <stop stopColor="#83FF00" stopOpacity="0"/>
              <stop offset="1" stopColor="#83FF00" stopOpacity="0.5"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className={styles.backgroundRight}>
        <svg width="243" height="237" viewBox="0 0 243 237" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="60.4278" height="427.45" transform="matrix(-0.978532 -0.206095 0.139287 -0.990252 109.376 237.382)" fill={`url(#${prefix}paint0_linear_8438_107902)`} fillOpacity="0.5"/>
          <rect width="80.5704" height="427.45" transform="matrix(-0.978532 -0.206095 0.139287 -0.990252 183.376 211.725)" fill={`url(#${prefix}paint1_linear_8438_107902)`} fillOpacity="0.4"/>
          <rect width="20.1426" height="427.45" transform="matrix(-0.978532 -0.206095 0.139287 -0.990252 19.7102 168.435)" fill={`url(#${prefix}paint2_linear_8438_107902)`} fillOpacity="0.4"/>
          <defs>
            <linearGradient id={`${prefix}paint0_linear_8438_107902`} x1="30.2139" y1="0" x2="30.2139" y2="427.45" gradientUnits="userSpaceOnUse">
              <stop stopColor="#83FF00" stopOpacity="0"/>
              <stop offset="1" stopColor="#83FF00" stopOpacity="0.5"/>
            </linearGradient>
            <linearGradient id={`${prefix}paint1_linear_8438_107902`} x1="40.2852" y1="0" x2="40.2852" y2="427.45" gradientUnits="userSpaceOnUse">
              <stop stopColor="#83FF00" stopOpacity="0"/>
              <stop offset="1" stopColor="#83FF00" stopOpacity="0.5"/>
            </linearGradient>
            <linearGradient id={`${prefix}paint2_linear_8438_107902`} x1="10.0713" y1="0" x2="10.0713" y2="427.45" gradientUnits="userSpaceOnUse">
              <stop stopColor="#83FF00" stopOpacity="0"/>
              <stop offset="1" stopColor="#83FF00" stopOpacity="0.5"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className={styles.backgroundMobile}>
        <svg width="359" height="178" viewBox="0 0 359 178" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="127" y="98.7014" width="60" height="351" transform="rotate(-85.164 127 98.7014)" fill={`url(#${prefix}paint0_linear_6922_91175)`} fillOpacity="0.7"/>
          <rect x="175.042" y="147.452" width="55.9512" height="351" transform="rotate(-85.164 175.042 147.452)" fill={`url(#${prefix}paint1_linear_6922_91175)`} fillOpacity="0.6"/>
          <rect x="209.146" y="19.9287" width="20" height="351" transform="rotate(-85.164 209.146 19.9287)" fill={`url(#${prefix}paint2_linear_6922_91175)`} fillOpacity="0.6"/>
          <defs>
            <linearGradient id={`${prefix}paint0_linear_6922_91175`} x1="157" y1="98.7014" x2="157" y2="449.701" gradientUnits="userSpaceOnUse">
              <stop stopColor="#83FF00" stopOpacity="0"/>
              <stop offset="1" stopColor="#83FF00" stopOpacity="0.5"/>
            </linearGradient>
            <linearGradient id={`${prefix}paint1_linear_6922_91175`} x1="203.018" y1="147.452" x2="203.018" y2="498.452" gradientUnits="userSpaceOnUse">
              <stop stopColor="#83FF00" stopOpacity="0"/>
              <stop offset="1" stopColor="#83FF00" stopOpacity="0.5"/>
            </linearGradient>
            <linearGradient id={`${prefix}paint2_linear_6922_91175`} x1="219.146" y1="19.9287" x2="219.146" y2="370.929" gradientUnits="userSpaceOnUse">
              <stop stopColor="#83FF00" stopOpacity="0"/>
              <stop offset="1" stopColor="#83FF00" stopOpacity="0.5"/>
            </linearGradient>
          </defs>
        </svg>

      </div>
    </div>
    {
      logo
      && <Logo
        logo={logo as Logos}
        className={clsx(
          styles.logo,
          logo === BannerLogo.engie && styles.logoEngie,
          logo === BannerLogo.EKWielrennen2024 && styles.logoEKWielrennen2024,
        )}
        darkMode
      />
    }
    {image && <div className={styles.image}>
      <Image src={image} profiles={[{
        name: 'width640',
        view: '1280w'
      }]} />
    </div>}
    <div className={styles.contentContainer}>
      <div className={styles.content}>
        <Title className={clsx(
          text && styles.contentTitleWithText,
        )} size={TitleSize.Small} desktopSize={TitleSize.XLarge}>{title}</Title>
        {subTitle && <Title className={clsx(
          text && styles.contentSubTitleWithText
        )} size={TitleSize.XSmall} desktopSize={TitleSize.Small}>{subTitle}</Title>}
        {text && <Paragraph className={styles.contentCaption}>{text}</Paragraph>}
      </div>
      <div className={styles.cta}>
        {
          variant === BannerVariant.appPromo
            ? <BannerAppCTA/>
            : buttonLabel && <Button size={ButtonSize.small} iconAfter={'chevron-right'}>
            {buttonLabel}
          </Button>
        }
      </div>
    </div>
  </Tag>
}

export default BannerDefault
