import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'

import { addQualifioCampaign } from '@sporza/frontend-web/app/features/sso/helpers'
import { useGdpr } from '@sporza/hooks'
import { Vendor } from '@sporza/utils/gdpr'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import styles from './embed-qualifio.module.scss'

interface EmbedQualifioComponentProps {
  componentProps: EmbedQualifioProps
}

interface EmbedQualifioProps {
  className?: ClassValue
  qualifioId?: string
  qualifioVariables?: string
  autoLoad?: boolean
}

const EmbedQualifio: FunctionComponent<EmbedQualifioProps> = (
  {
    className,
    qualifioId,
    qualifioVariables,
    autoLoad
  }
) => {
  const [shouldLoadQualifioWidget, setShouldLoadQualifioWidget] = useState(false)
  const { gdprVendorAllowed, vendorNotAllowedComponent } = useGdpr(Vendor.Qualifio)
  const id = `vrt-qualifio-${qualifioId}`

  const initQualifio = () => {
    (function (b, o, n, u, s) {
      let a, t
      a = b.createElement(u)
      a.async = 1
      a.src = s

      const callback = function () {
        _qual_async.push([
          'createIframe',
          id,
          'player.qualifio.com',
          '20',
          qualifioId,
          '100%',
          '1200',
          `${qualifioVariables}&label14_nvarchar=${document.referrer}`,
          '',
          '',
          'max-width:810px;margin:0 auto;'
        ])
      }
      a.onload = callback

      t = b.getElementsByTagName(u)[0]
      t.parentNode.insertBefore(a, t)
      o[n] = o[n] || []
    })(
      document,
      window,
      '_qual_async',
      'script',
      '//interactief.vrt.be/kit/qualp.2.min.js'
    )
  }

  const initQualifioSSO = () => {
    const w = window.parent || window
    w._qual_async = w._qual_async || []
    const params = new URLSearchParams(w.location.search)
    const id_token = params.get('id_token_hint')
    if (id_token) {
      w._qual_async.push(['setMetaData', 'id_token_hint', id_token, id])
      w.history.replaceState({}, w.document.title, w.document.location.href.split('?')[0])
      console.log('id token hint set')
    } else {
      w._qual_async.push(['setMetaData', 'id_token_hint', '', id])
      console.log('id token hint cleared')
    }
  }

  useEffect(() => {
    setShouldLoadQualifioWidget(autoLoad || gdprVendorAllowed)
  }, [autoLoad, gdprVendorAllowed])

  useEffect(() => {
    if (shouldLoadQualifioWidget && qualifioId){
      addQualifioCampaign(qualifioId)
      initQualifioSSO()
      initQualifio()
    }
  }, [shouldLoadQualifioWidget])

  return qualifioId
    ? <div
      className={clsx(
        styles.embedQualifio,
        className
      )}
    >
      {
        shouldLoadQualifioWidget
          ? <div id={id}></div>
          : !gdprVendorAllowed
            ? vendorNotAllowedComponent
            : null
      }
    </div>
    : null
}

export default EmbedQualifio

export type {
  EmbedQualifioProps,
  EmbedQualifioComponentProps
}
