import clsx from 'clsx'
import { FunctionComponent } from 'react'

import Caption from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Title, { TitleSize } from '../../../atoms/title'
import { BannerProps } from '../banner'

import styles from './small.module.scss'

const BannerSmall: FunctionComponent<BannerProps> = (
  {
    title,
    subTitle,
    link,
    className,
    ref,
    click
  }
) => {
  const Tag = link
    ? 'a'
    : 'div'

  return <Tag
    className={clsx(
      styles.wrapper,
      className
    )}
    onClick={() => click()}
    href={link ? link : undefined}
    target={link ? '_blank' : undefined}
    ref={ref}
  >
    <div className={styles.content}>
      <Title className={styles.title} size={TitleSize.XSmall}>{title}<Icon className={styles.icon} name={'chevron-right'} /></Title>
      {subTitle && <Caption className={styles.caption}>{subTitle}</Caption>}
    </div>
  </Tag>
}

export default BannerSmall
