import type { FunctionComponent } from 'react'

import { useApi } from '@sporza/hooks'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Icon from '../../../atoms/icon'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import { Icons } from '../../../design-tokens/iconography/icons'
import Button from '../../../molecules/button'
import Scoreboard, { ScoreboardComponentProps } from '../../scoreboard'
import { NavigationIcons } from '../../section/layouts/slider'
import styles from '../matchday.module.scss'

interface MatchdayTableProps {
  title?: string
  subTitle?: string
  link?: string
  items: ScoreboardComponentProps[]
  nextUrl?: string
  prevUrl?: string
  tag?: string | TitleElement
}

const MatchdayTable: FunctionComponent<MatchdayTableProps> = (
  props
) => {
  const [sportApiUrl, setSportApiUrl] = useState<string>('')
  const [prevIcon, setPrevIcon] = useState<Icons>(props.prevUrl ? NavigationIcons.Left : NavigationIcons.LeftDisabled)
  const [nextIcon, setNextIcon] = useState<Icons>(props.nextUrl ? NavigationIcons.Right : NavigationIcons.RightDisabled)

  const {
    isPending,
    isFetching,
    error,
    data,
    refetch
  } = useApi(props, {
    keyPrefix: 'matchdays',
    url: sportApiUrl
  })

  useEffect(() => {
    if (!isPending){
      setPrevIcon(data?.prevUrl ? NavigationIcons.Left : NavigationIcons.LeftDisabled)
      setNextIcon(data?.nextUrl ? NavigationIcons.Right : NavigationIcons.RightDisabled)
    }
  }, [data])

  useEffect(() => {
    if (sportApiUrl) refetch()
  }, [sportApiUrl])

  const {
    title,
    subTitle,
    tag,
    items,
    link,
    prevUrl,
    nextUrl
  } = data

  const handlePrev = () => {
    if (prevUrl) setSportApiUrl(prevUrl)
  }

  const handleNext = () => {
    if (nextUrl) setSportApiUrl(nextUrl)
  }

  const titleElement = title
    && <Title
      size={TitleSize.Large}
      link={link}
      tag={tag}
    >{title}</Title>

  const subTitleElement = subTitle
    && <Title
      size={TitleSize.XSmall}
    >{subTitle}</Title>

  return <div
    className={clsx(
      styles.matchdayWrapper
    )}>
    <div className={styles.titleWrapper}>
      <div>
        {titleElement}
        {subTitleElement}
      </div>
      {(nextUrl || prevUrl) && <nav className={styles.actions}>
        <Button
          variant="tertiary"
          iconBefore={prevIcon}
          onClick={() => handlePrev()}
        />
        {data && isFetching && <Icon name={'spinner'} rotate={true} />}
        {data && error && <Icon name={'error'} className={styles.error} />}
        {!(data && isFetching) && !(data && error) && <span className={styles.dummy} />}
        <Button
          variant="tertiary"
          iconBefore={nextIcon}
          onClick={() => handleNext()}
        />
      </nav>}
    </div>
    <div className={styles.matchday}>
      {
        items?.map((item: ScoreboardComponentProps, index: number) =>
          <Scoreboard
            className={styles.board}
            key={`${index}-${item.componentProps.status}-${JSON.stringify(item.componentProps.home)}-${JSON.stringify(item.componentProps.away)}`}
            hideCompetitionName={true}
            {...item.componentProps}
          />
        )
      }
    </div>
  </div>
}

export default MatchdayTable

export type {
  MatchdayTableProps
}
