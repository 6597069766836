import type { FunctionComponent } from 'react'
import type { Icons } from '../../../../design-tokens/iconography/icons'
import type { EventSetProps } from '../../../../molecules/field-timeline/event-set'
import type { RulerItemProps } from '../../../../molecules/field-timeline/ruler'
import type { IntermediatesProps } from '../../../../molecules/intermediates/intermediates'
import type { ScoreSetsProps } from '../../../../molecules/score/layout'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Icon from '../../../../atoms/icon'
import Heading from '../../../../molecules/heading'
import Score, { SportTypes, StatusTypes } from '../../../../molecules/score'
import { AccordionProps } from '../../../accordion'
import { ScoreboardLayoutTypes } from '../../index'
import styles from '../../scoreboard.module.scss'

interface ScoreboardDistanceProps extends ScoreSetsProps {
  competitionName?: string
  darkMode?: boolean
  date?: string
  distance?: number
  distanceToGo?: number
  endLabel?: string
  eventSets?: EventSetProps[]
  groups?: AccordionProps[]
  intermediates?: IntermediatesProps
  isLastItem?: boolean
  label?: string
  layout?: ScoreboardLayoutTypes
  ruler?: RulerItemProps[]
  showHeader?: boolean
  showLinks?: boolean
  sport: SportTypes | string
  stage?: string
  startLabel?: string
  status?: StatusTypes | string
  temperature?: number
  title?: string
  type?: string
  typeIcon?: string | Icons
  weatherIcon?: Icons
  winner?: { name: string, shortName?: string, url?: string }
  winners?: [{ position: number, name: string, shortName?: string, url?: string }]
}

const DefaultDistanceBoard: FunctionComponent<ScoreboardDistanceProps> = (
  {
    competitionName,
    distance,
    distanceToGo,
    endLabel,
    isLastItem= false,
    label,
    layout,
    showHeader,
    showLinks,
    sport,
    startLabel,
    status,
    temperature,
    type,
    typeIcon,
    weatherIcon,
    winner,
  }
) => {
  const Tag = showLinks && winner?.url ? 'a' : 'div'

  return <>
    {showHeader && competitionName && <Heading className={styles.competitionHeading} variant={'secondary'} align={layout === 'compact' ? 'Left' : 'Center'}>{competitionName}</Heading>}
    <div className={clsx(
      styles.scoreboardContent,
      isLastItem && styles.lastItem
    )}>
      <div className={styles.info}>
        <div className={styles.list}>
          {type && <Caption className={styles.listItem}>{typeIcon && <Icon name={typeIcon} />}{type}</Caption>}
          {temperature && <Caption className={styles.listItem}>{weatherIcon && <Icon name={weatherIcon} />}{temperature} &deg;C</Caption>}
          {distance && <Caption className={styles.listItem}><Icon name={'cycling-distance'} />{distance} km</Caption>}
        </div>
        <Caption>{startLabel}</Caption>
        <Caption>{endLabel}</Caption>
        {winner && <Tag className={styles.winner} href={showLinks ? winner.url : undefined}><Caption weight={'Bold'}><Icon name={'cycling-hotseat'} />{winner.name}</Caption></Tag>}
      </div>
      <Score layout={'default'} sport={sport} distance={distance} distanceToGo={distanceToGo} status={status} label={label} typeIcon={typeIcon} />
    </div>
  </>
}

export default DefaultDistanceBoard

export type {
  ScoreboardDistanceProps
}
