import { mapVoteToLineup } from '@sporza/design-system/organisms/voting/layout/iedereen-bondscoach/helpers'
import { InteractionService } from '@sporza/services'
import { logger } from '@sporza/services/logger'
import { useEffect, useState } from 'react'

import { useAuth } from './use-auth'

const useInteractionService = (votingBaseUrl: string, slug?: string): any => {
  if (!slug) {
    logger.warn('interaction service - no slug provided')
    return
  }

  const [vote, setVote] = useState<any>()
  const [hasFetchedVote, setHasFetchedVote] = useState(false)
  const interactionService = new InteractionService(votingBaseUrl)

  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (isLoggedIn) {
      interactionService.setAccessToken()
    }
  }, [isLoggedIn, interactionService])

  const fetchVote = async () => {
    try {
      const result = await interactionService.getVote(slug)

      setHasFetchedVote(true)

      if (!result) {
        logger.warn('interaction service - no vote found')
        return
      }

      const mappedResults = mapVoteToLineup(result)

      if (
        !mappedResults.players
        || !mappedResults.lineup
      ) {
        return
      }

      setVote(mappedResults)
    } catch (error) {
      logger.error('interaction service - failed to fetch vote', error)
    }
  }

  const createVote = async (vote: any) => {
    try {
      return await interactionService.createVote(vote)
    } catch (error) {
      logger.error('interaction service - failed to create vote', error)
    }
  }

  return {
    vote,
    fetchVote,
    createVote,
    hasFetchedVote
  }
}

export {
  useInteractionService
}
