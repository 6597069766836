import type { FunctionComponent } from 'react'
import type { PlayernameProps } from '../../../../molecules/playername'
import type { ScoreSetsProps } from '../../../../molecules/score/layout'
import type { TeamnameProps } from '../../../../molecules/teamname'

import clsx from 'clsx'

import Heading from '../../../../molecules/heading'
import Playername from '../../../../molecules/playername'
import Score, { StatusTypes } from '../../../../molecules/score'
import { ScoreboardLayoutTypes } from '../../index'
import styles from '../../scoreboard.module.scss'

interface ScoreboardSetsProps extends ScoreSetsProps {
  away: [PlayernameProps['player']] | TeamnameProps['team']
  competitionName?: string
  darkMode?: boolean
  home: [PlayernameProps['player']] | TeamnameProps['team']
  isLastItem?: boolean
  label?: string
  layout?: ScoreboardLayoutTypes
  showHeader?: boolean
  showLinks?: boolean
  status?: string | StatusTypes
}

const DefaultSetsBoard: FunctionComponent<ScoreboardSetsProps> = (
  {
    away,
    competitionName,
    home,
    isLastItem= false,
    label,
    layout,
    sets,
    showHeader,
    showLinks,
    sport,
    status,
  }
) => {
  const homePlayers = home as [PlayernameProps['player']]
  const awayPlayers = away as [PlayernameProps['player']]

  return <>
    {showHeader && competitionName && <Heading className={styles.competitionHeading} variant={'secondary'} align={layout === 'compact' ? 'Left' : 'Center'}>{competitionName}</Heading>}
    <div className={clsx(
      styles.scoreboardContent,
      isLastItem && styles.lastItem
    )}>
      <div className={styles.setsPlayers}>
        <span className={styles.setsPlayer}>
          {homePlayers?.map((player, index) => <Playername size={'Large'} key={`${index}-${player?.name}`} player={player} showLinks={showLinks} />)}
        </span>
        <span className={styles.setsPlayer}>
          {awayPlayers?.map((player, index) => <Playername size={'Large'} key={`${index}-${player?.name}`} player={player} showLinks={showLinks} />)}
        </span>
      </div>
      <Score layout={'default'} sets={sets} sport={sport} status={status} label={label} />
    </div>
  </>
}

export default DefaultSetsBoard

export type {
  ScoreboardSetsProps
}
