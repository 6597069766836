import type { FunctionComponent } from 'react'

import { VotingLayout, VotingPhase } from './config'
import { IedereenBondscoach } from './layout'
import styles from './voting.module.scss'

interface VotingProps {
  [key: string]: any

  slug?: string
  votingId?: string
  phase?: VotingPhase | string
  endDateTime?: string
  layout?: VotingLayout
  options?: any[]
  votingBaseUrl: string
  designSystemBaseUrl?: string
  handlePhaseChange?: (newPhase: VotingPhase, vote?: any, players?: any) => void
}

const layoutComponents: Record<VotingLayout, any> = {
  [VotingLayout.Default]: IedereenBondscoach,
  [VotingLayout.IedereenBondscoach]: IedereenBondscoach
}

const Voting: FunctionComponent<VotingProps> = (props) => {
  const Layout = layoutComponents[props.layout as VotingLayout] || layoutComponents[VotingLayout.Default]

  return <Layout
    {...props}
    designSystemBaseUrl={props.designSystemBaseUrl}
    className={styles.voting}
  />
}

export {
  Voting
}

export type {
  VotingProps
}

