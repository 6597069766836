import Button, { ButtonVariant } from '@sporza/design-system/molecules/button'
import Card from '@sporza/design-system/organisms/card'
import { enableConsent, Vendor, VendorId, VendorPurposeId } from '@sporza/utils/gdpr'

const vendorNotAllowed = (vendor: Vendor, callback?: () => void) =>
  <Card highlight={true}>
    Hier staat ingevoegde content uit een social media netwerk dat cookies wil schrijven of uitlezen ({vendor}).
    U heeft hiervoor geen toestemming gegeven. <Button
    variant={ButtonVariant.tertiary}
    onClick={() =>
      enableConsent({
        vendorId: VendorId[vendor as keyof typeof VendorId],
        vendorPurposeId: VendorPurposeId[vendor as keyof typeof VendorPurposeId],
        callback
      })
    }
  >Klik hier om dit alsnog toe te laten</Button>
  </Card>

export {
  vendorNotAllowed
}
