import { Breakpoints } from '@sporza/design-system/design-tokens/breakpoints'
import { useEffect,useState } from 'react'

const useBreakpoint = () => {
  const [width, setWidth] = useState<number>(Breakpoints.small)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    screenSize: width >= Breakpoints.xxlarge ? Breakpoints.xxlarge :
      width >= Breakpoints.xlarge ? Breakpoints.xlarge :
        width >= Breakpoints.large ? Breakpoints.large :
          width >= Breakpoints.medium ? Breakpoints.medium :
            Breakpoints.small,
    // like css breakpoints
    getOption: (options: any) => {
      switch (true) {
        case width >= Breakpoints.xxlarge && options[Breakpoints.xxlarge] !== undefined:
          return options[Breakpoints.xxlarge]
        case width >= Breakpoints.xlarge && options[Breakpoints.xlarge] !== undefined:
          return options[Breakpoints.xlarge]
        case width >= Breakpoints.large && options[Breakpoints.large] !== undefined:
          return options[Breakpoints.large]
        case width >= Breakpoints.medium && options[Breakpoints.medium] !== undefined:
          return options[Breakpoints.medium]
        case width >= Breakpoints.small && options[Breakpoints.small] !== undefined:
          return options[Breakpoints.small]
        default:
            return undefined
      }
    }
  }
}

export { useBreakpoint }
