import type { FunctionComponent } from 'react'
import type { BannerClickArguments } from '@sporza/tracking/events'

import { useBannerImpression } from '@sporza/tracking'
import { bannerClickEvent } from '@sporza/tracking/events'
import { getPathName, getPermalinkId } from '@sporza/utils/urls'
import { useRef } from 'react'

import BannerSmall from './layout/small'
import { BannerLayout, BannerLogo, BannerTheme } from './index'
import BannerDefault from './layout'

const layoutComponents = {
  [BannerLayout.default]: BannerDefault,
  [BannerLayout.small]: BannerSmall
}

interface BannerProps {
  layout?: BannerLayout
  image?: string
  title?: string
  subTitle?: string
  text?: string
  buttonLabel?: string
  theme?: string | BannerTheme
  logo?: BannerLogo
  variant?: string
  link?: string
  className?: string,
  designSystemBaseUrl?: string
  ref?: any
  click?: any,
  useOutset?: boolean
}

const Banner: FunctionComponent<BannerProps> = (
  props
) => {
  const {
    title,
    link,
    layout = BannerLayout.default
  } = props

  const bannerRef = useRef(null)
  const bannerContext = {
    bannerTitle: title ?? '',
    componentName: 'Banner',
    targetPageUrl: link,
    targetPageName: getPathName(link),
    targetPageId: getPermalinkId(link)
  }

  const trackBannerClick = (bannerContext: any) => {
    bannerClickEvent(bannerContext as BannerClickArguments)

    return true
  }

  useBannerImpression(
    {
      ref: bannerRef,
      ...bannerContext
    }
  )

  const Layout = layoutComponents[layout as keyof typeof layoutComponents]

  return <Layout
    {...props}
    ref={bannerRef}
    click={() => trackBannerClick(bannerContext)}
  />
}

export default Banner

export type {
  BannerProps
}
