enum VotingLayout {
  Default = 'default',
  IedereenBondscoach = 'iedereen-bondscoach',
}

enum VotingPhase {
  countdown = 'countdown',
  voting = 'voting',
  results = 'results',
}

export {
  VotingLayout,
  VotingPhase
}
