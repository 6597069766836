import type { FunctionComponent } from 'react'
import type { EventSetProps } from '../../../../molecules/field-timeline/event-set'
import type { PlayernameProps } from '../../../../molecules/playername'
import type { ScoreScoreProps } from '../../../../molecules/score/layout'
import type { TeamnameProps } from '../../../../molecules/teamname'
import type { TableProps } from '../../../table'

import clsx from 'clsx'

import Title from '../../../../atoms/title'
import Heading from '../../../../molecules/heading'
import Score, { ScoreLayoutTypes, SportTypes, StatusTypes } from '../../../../molecules/score'
import Teamname from '../../../../molecules/teamname'
import { ScoreboardLayoutTypes } from '../../index'
import styles from '../../scoreboard.module.scss'

interface ScoreboardScoreComponent {
  componentProps: ScoreboardScoreProps
}

interface ScoreboardScoreProps extends ScoreScoreProps {
  away: TeamnameProps['team'] | [PlayernameProps['player']]
  competitionName?: string
  darkMode?: boolean
  date?: string
  designSystemBaseUrl?: string
  eventSets?: EventSetProps[]
  goals?: TableProps
  home: TeamnameProps['team'] | [PlayernameProps['player']]
  isLastItem?: boolean
  label?: string
  layout?: ScoreboardLayoutTypes
  left?: string
  meta?: string
  relatedMatch?: ScoreboardScoreComponent
  right?: string
  showHeader?: boolean
  showLinks?: boolean
  showLogos?: boolean
  sport: SportTypes | string
  status?: StatusTypes | string
  url?: string
}

const DefaultScoreBoard: FunctionComponent<ScoreboardScoreProps> = (
  {
    away,
    competitionName,
    designSystemBaseUrl,
    home,
    isLastItem = false,
    label,
    layout,
    left,
    right,
    score,
    showHeader,
    showLinks,
    showLogos,
    status,
    sport,
  }
) => {
  return <>
    {showHeader && competitionName && <Heading className={styles.competitionHeading} variant={'secondary'}
                                               align={layout === 'compact' ? 'Left' : 'Center'}>{competitionName}</Heading>}
    {(left || right) && <div className={styles.scoreboardHeading}>
      {left && <Title>{left}</Title>}
      {right && <Title>{right}</Title>}
    </div>}
    <div className={clsx(
      styles.scoreboardContent,
      isLastItem && styles.lastItem
    )}>
      <Teamname
        size={'Large'}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={home as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={showLinks}
        logoPosition={layout === 'compact' ? 'top' : 'left'}
      />

      <Score layout={ScoreLayoutTypes.Default}
             score={score}
             sport={sport}
             status={status}
             label={label}
      />

      <Teamname
        size={'Large'}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={away as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={showLinks}
        logoPosition={layout === 'compact' ? 'top' : 'right'}
        isAway={true}
      />
    </div>
  </>
}

export default DefaultScoreBoard

export type {
  ScoreboardScoreProps,
  ScoreboardLayoutTypes
}
