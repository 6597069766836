export const setStorage = (key:string, array: object) => typeof localStorage !== 'undefined' && localStorage.setItem(key, JSON.stringify(array))

export const getStorage = (key: string) => {
    if (typeof localStorage !== 'undefined'){
        const recentSearches = JSON.parse(''+localStorage.getItem(key))
        if (recentSearches) return recentSearches.sort((a: any, b: any) => (a.timestamp > b.timestamp) ? -1 : 1)
    }
    return []
}

export const removeStorage = (key: string) => {
    setStorage(key, [])
    window.location.reload()
}
