import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Tab, { TabProps } from '../../organisms/tab'

import Matches from './components/Matches'
import styles from './live-dashboard.module.scss'

enum LiveDashboardLayout {
  Default = 'Default',
}
enum LiveDashboardBackground {
  Default = 'Default',
  Soccer = 'Soccer',
  Basketball = 'Basketball',
  Cycling = 'Cycling',
  None = 'None'
}


interface LiveDashboardProps {
  items?: any
  label?: string
  background?: string | LiveDashboardBackground
  darkMode?: boolean
  maxItems?: number
}

const LiveDashboard: FunctionComponent<LiveDashboardProps> = ({
  label,
  background= LiveDashboardBackground.Default,
  darkMode,
  items,
  maxItems = 5
}) => {
  return items
    ? <section
      className={clsx(
      background !== LiveDashboardBackground.None && styles.backgroundDefault,
      styles.liveDashboard,
      background === LiveDashboardBackground.Soccer && styles.backgroundSoccer,
      background === LiveDashboardBackground.Basketball && styles.backgroundBasketball,
      background === LiveDashboardBackground.Cycling && styles.backgroundCycling,
      darkMode && styles.dark
    )}>
      {
        items
          ? <Tab key={label}
             align={'right'}
             title={label}
             items={items.map((sport: any) => {
               return {
                 label: sport.label,
                 content: <Matches sport={sport} maxItems={maxItems}  />
               }
             })}
          />
          : null
      }
    </section>
    : null
}

export default LiveDashboard

export type {
  LiveDashboardProps
}

export {
  LiveDashboardLayout,
  LiveDashboardBackground,
}



