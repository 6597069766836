import type { FilterOption } from '../../../molecules/filter'
import type { MatchDayComponentProps } from '../../matchday'
import type { TabSectionComponentProps } from '../../tab-section'

import { useApi } from '@sporza/hooks'
import { FunctionComponent, useEffect } from 'react'
import { useState } from 'react'

import { TitleElement } from '../../../atoms/title'
import Filter, { FilterType } from '../../../molecules/filter'
import Cell from '../../cell'
import Grid from '../../grid'
import Matchday from '../../matchday'
import TabSection from '../../tab-section'
import styles from '../result.module.scss'

interface ResultDefaultProps {
  id?: string
  matchday?: MatchDayComponentProps
  ranking?: TabSectionComponentProps
  options?: FilterOption[]
  isGrouped?: boolean
}

const ResultDefault: FunctionComponent<ResultDefaultProps> = (props) => {
  const [sportApiUrl, setSportApiUrl] = useState<string>('')

  const { data, refetch } = useApi(props, {
    keyPrefix: sportApiUrl ? 'result' : props,
    url: sportApiUrl
  })

  useEffect(() => {
    if (sportApiUrl) refetch()
  }, [sportApiUrl])

  const getFirstOptions = () => {
    return props.options && props.options?.map(item => { return { ...item, items: undefined }})
  }

  const getSecondOptions = () => {
    const activeItems = props?.options?.filter(item => item.active) || []
    const item = activeItems.length > 0 ? activeItems[0] : props?.options && props?.options[0]

    return item && item.items && item.items.map(item => { return { ...item, items: undefined }})
  }

  const [firstOptions, setFirstOptions] = useState<FilterOption[]>(getFirstOptions())
  const [secondOptions, setSecondOptions] = useState<FilterOption[]>(getSecondOptions())
  //
  const handleSelect = (option: FilterOption) => {
    switch (true) {
      case !props.isGrouped && !option.value: {
        const matchingOption = props.options?.find(item => item.label === option.label)
        if (matchingOption?.items && matchingOption?.items?.length > 0) setSecondOptions(matchingOption?.items || [])
        break
      }
      case option.value !== undefined:
        setSportApiUrl(option.value)
        break
    }
  }

  const filterElement = () => {
    if (!props.options) return

    return props.isGrouped
      ? <Filter options={props.options} type={FilterType.Dropdown} onSelect={handleSelect} />
      : <div className={styles.filters}>
          {firstOptions && <Filter options={firstOptions} type={FilterType.Dropdown} onSelect={handleSelect} />}
          {secondOptions && <Filter options={secondOptions} type={FilterType.Dropdown} onSelect={handleSelect} />}
        </div>
  }

  if (data)
    return <Grid className={styles.result}>
      <Cell>
        {filterElement()}
      </Cell>
      <Cell size={data.ranking ? 2 : 4}>
        {data.matchday && <Matchday tag={TitleElement.H3} {...data.matchday.componentProps} />}
      </Cell>
      <Cell size={data.matchday ? 2 : 4}>
        {data.ranking && <TabSection {...data.ranking.componentProps} />}
      </Cell>
    </Grid>
}

export default ResultDefault

export type {
  ResultDefaultProps
}
