import type { FunctionComponent } from 'react'
import type { TeamnameProps } from '../../../../molecules/teamname'
import type { ScoreboardLayoutTypes } from '../../index'
import type { ScoreboardScoreProps } from '../default'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import FieldTimeline from '../../../../molecules/field-timeline/field-timeline'
import Score, { ScoreLayoutTypes, StatusTypes } from '../../../../molecules/score'
import Teamname, { TeamnameSize } from '../../../../molecules/teamname'
import Table from '../../../table'
import styles from '../../scoreboard.module.scss'

const DetailedScoreBoard: FunctionComponent<ScoreboardScoreProps> = (
  {
    sport,
    status,
    label,
    home,
    away,
    score,
    showLogos,
    showLinks,
    eventSets,
    goals,
    meta,
    relatedMatch,
    designSystemBaseUrl
  }
) => {
  const Tag = relatedMatch?.componentProps.url ? 'a' : 'div'

  return <>
    <div className={clsx(
      styles.scoreboardContent
    )}>
      <Teamname
        size={TeamnameSize.XLarge}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={home as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={showLinks}
        logoPosition='top'
      />

      <Score layout={ScoreLayoutTypes.Detailed}
        score={score}
        sport={sport}
        status={status}
        label={label}
      />

      <Teamname
        size={TeamnameSize.XLarge}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={away as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={showLinks}
        logoPosition='top'
        isAway={true}
      />
    </div>
    {relatedMatch && <Tag
        className={clsx(
          styles.relatedMatch,
          styles.scoreboardContent
        )}
        href={relatedMatch?.componentProps.url ? relatedMatch?.componentProps.url : undefined}
    >
      <Teamname
        size={TeamnameSize.Small}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={relatedMatch.componentProps.home as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={false}
        logoPosition={'left'}
      />

      <Score layout={ScoreLayoutTypes.Default}
        score={relatedMatch.componentProps.score}
        sport={sport}
        status={relatedMatch.componentProps.status}
        label={relatedMatch.componentProps.label}
      />

      <Teamname
        size={TeamnameSize.Small}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={relatedMatch.componentProps.away as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={false}
        logoPosition='right'
        isAway={true}
      />
    </Tag>}
    {eventSets && <FieldTimeline status={status as StatusTypes} eventSets={eventSets}/>}
    {meta && <Caption className={styles.meta}>{meta}</Caption>}
    {goals && <Table className={styles.goals} {...goals} />}
  </>
}

export default DetailedScoreBoard

export type {
  ScoreboardScoreProps,
  ScoreboardLayoutTypes
}
