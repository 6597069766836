import { addContextToEvent } from '../utils'

import { getPageEventData } from './page-impression'

interface BannerImpressionArguments {
  targetPageUrl?: string
  targetPageName?: string
  targetPageId?: string
  bannerTitle: string
  componentName?: string
  componentId?: string
  bannerSubtitle?: string
  bannerType?: string
  componentPosition?: number
  componentCount?: number
  calculationId?: string
  featureKey?: string
  featureValue?: string
  featureSource?: string
  featureRuleId?: string
  featureExtra?: string
}

const bannerImpressionEvent = (
  {
    targetPageUrl,
    targetPageName,
    targetPageId,
    bannerTitle,
    componentName,
    componentId,
    bannerSubtitle,
    bannerType,
    componentPosition,
    componentCount,
    calculationId,
    featureKey,
    featureValue,
    featureSource,
    featureRuleId,
    featureExtra
  }: BannerImpressionArguments
) => {
  if (typeof window === 'undefined') {
    console.warn('sporza eba - no banner impression triggered, not running in browser')

    return
  }

  const trackUsingTemplate = window.VRT?.EBA?.EDDLHelper?.trackUsingTemplate
  const templates = window.VRT?.EBA?.defaultTemplates

  if (!trackUsingTemplate || !templates) {
    console.warn('sporza eba - could not find EBA utils to track banner impression')

    return
  }

  try {
    const eventData = {
      // target
      '$tapu': targetPageUrl,
      '$tapn': targetPageName,
      '$tapi': targetPageId,

      '$bati': bannerTitle,

      // optional
      '$bana': componentName,
      '$baid': componentId,
      '$bast': bannerSubtitle,
      '$baty': bannerType,
      '$bacp': componentPosition,
      '$bacc': componentCount,
      '$baci': calculationId,

      // feature
      '$feke': featureKey,
      '$feva': featureValue,
      '$fesr': featureSource,
      '$feri': featureRuleId,
      '$feex': featureExtra,
    }

    const pageEventData = getPageEventData()

    let bannerImpression = { ...templates.banner_impression_1_1_0 }
    bannerImpression = addContextToEvent(bannerImpression, 'context_target')
    bannerImpression = addContextToEvent(bannerImpression, 'context_page')

    trackUsingTemplate(
      bannerImpression,
      {
        ...eventData,
        ...pageEventData
      }
    )
  } catch (e) {
    console.error('failed to send banner impression event', e)
  }
}

export {
  bannerImpressionEvent
}

export type {
  BannerImpressionArguments
}
