const mapVoteToLineup = (vote: any): any => {
  return {
    lineup: vote.motivation,
    players: vote.selection?.map((player: any) => ({
      ...player.external,
      interactionOptionId: player.id,
      selected: true,
    })),
  }
}

export {
  mapVoteToLineup
}
