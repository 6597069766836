enum BannerTheme {
  light = 'light',
  dark = 'dark',
  vrtmax = 'vrtmax',
  OlympischeSpelen2024 = 'olympische-spelen-2024',
  ParalympischeSpelen2024 = 'olympische-spelen-2024',
  EKWielrennen2024 = 'ek-wielrennen-2024'
}

enum BannerVariant {
  default = 'default',
  appPromo = 'appPromo'
}

enum BannerLayout {
  default = 'default',
  small = 'small'
}

enum BannerLogo {
  vrtmax = 'vrtmax',
  engie = 'engie',
  EKWielrennen2024 = 'ek-wielrennen-2024'
}

export {
  BannerTheme,
  BannerVariant,
  BannerLayout,
  BannerLogo
}
